<template>
  <v-container>
      <v-row>
          <v-col cols="12">
              <v-card elevation="5">
                  <v-card-title>Notifications</v-card-title>
                  <v-card-text>
                      <div v-if="notifications" class="notifications">
                          <div   v-for="item in notifications" :key="item.items[0].order_id">
                          <h5><strong>{{item.user}}</strong> has placed a new order for: <strong v-for=" orderedProducts in item.items" :key="orderedProducts.id"> <li>{{orderedProducts.product.name}} </li>  </strong></h5>
                          <p class="font-small"><v-icon class="pr-2" size="14">mdi-clock</v-icon>  {{ humanizeTime(item.created_at )}}</p>
                          <v-divider></v-divider>
                          </div>
                      </div>

                          <v-skeleton-loader v-else
                           v-bind="attrs"
                 type="list-item-three-line"
        ></v-skeleton-loader>
                  </v-card-text>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
    data(){
        return{
        notifications:null,
        }
    },
    methods:{
        humanizeTime(date){
            return moment(String(date)).fromNow();
        }
    },
    created(){
    this.$store.dispatch("getAllNotification").then((res)=>{
        this.notifications=res;
    })
      this.$store.dispatch("markAsRead")
      this.show=false;
    },
   

}
</script>

<style>
.font-small{
    font-size: 0.7rem;
    margin-bottom: 0.25rem !important;
    color: rgba(0, 0, 0) !important;
}
.notifications h5{
    color: rgba(0, 0, 0) !important;
}
</style>